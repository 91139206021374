import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Content from "../components/content"
import { Button } from "../components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faDribbble,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import Typist from "react-typist"

import * as styles from "./index.module.scss"

const SocialIcons = props => {
  const yml = props.data.social
  return (
    <div className={styles.icons}>
      {yml.map(item => {
        if (item.title === "github") {
          return (
            <a
              className={styles.link}
              href={item.url}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )
        } else if (item.title === "dribbble") {
          return (
            <a
              className={styles.link}
              href={item.url}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faDribbble} />
            </a>
          )
        } else if (item.title === "linkedin") {
          return (
            <a
              className={styles.link}
              href={item.url}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          )
        }
      })}
    </div>
  )
}

const Headline = props => {
  const yml = props.data
  return (
    <section className={styles.headline}>
      {yml.headline.map((item, index) => {
        return (
          <Typist cursor={{ show: false }}>
            <h1 key={`headline_item_${index}`}>{item}</h1>
          </Typist>
        )
      })}
      <div className={styles.cta}>
        <Button>
          <a href="mailto:dev@dimitrisprama.com">Contact me</a>
        </Button>
        <SocialIcons data={yml} />
      </div>
    </section>
  )
}

const Avatar = props => {
  const yml = props.data.avatar
  return (
    <section className={styles.avatar}>
      <div className={styles.dots}>
        <GatsbyImage
          image={yml.path.childImageSharp.gatsbyImageData}
          alt={yml.alt}
        />
      </div>
    </section>
  )
}

const IndexPage = ({ data }) => {
  const yml = data.allDataYaml.edges[0].node
  return (
    <Layout>
      <Content>
        <div className={styles.index}>
          <Headline data={yml} />
          <Avatar data={yml} />
        </div>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allDataYaml(sort: { fields: name }) {
      edges {
        node {
          name
          headline
          avatar {
            alt
            path {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 600
                  width: 600
                  webpOptions: { quality: 100 }
                  formats: WEBP
                )
              }
            }
          }
          social {
            title
            url
          }
        }
      }
    }
  }
`
